<template>
  <div>
    <b-row>
      <b-col xl="12" lg="12" md="12" sm="12" cols="12">
        <b-card class="card-custom h-100" footer-tag="footer" footer-class="text-right">
          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12" cols="12">
              <h4 class="mb-5 ml-8 font-weight-bold text-dark">Page Detail</h4>
            </b-col>
          </b-row>

          <b-form-group class="text-muted">
            <b-card-body>
              <b-row>
                <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                  <b-alert variant="success" dismissible fade :show="show" v-on:dismissed="show = false">
                    {{ response }}
                  </b-alert>
                  <b-alert variant="danger" dismissible fade :show="showError" v-on:dismissed="showError = false">
                    {{ response_error }}
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-10">
                  <label class="text-muted">Page Name</label>
                  <b-form-input v-model="pageName"></b-form-input>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form-group>
          <template #footer>
            <b-button variant="primary" class="font-weight-bold px-10" v-on:click="savePage">
              Save
            </b-button>
          </template>
        </b-card>
      </b-col></b-row
    >
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'contracts-new',
  mounted() {
    this.paramPageId = this.$route.params.id
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Pages' }])
  },
  data() {
    return {
      editorData: '',
      editorConfig: {},
      paramPageId: '',
      pageName: '',
      show: false,
      response: '',
      showError: false,
      response_error: '',
    }
  },

  methods: {
    savePage() {
      if (this.pageName == '') {
        this.response_error = 'Please enter a page name.'
        this.showError = 3
      } else {
        ApiService.setHeader()
        ApiService.post('page', {
          value: this.pageName,
        }).then(() => {
          this.pageName = ''
          this.response = 'Success'
          this.show = 3
        })
      }
    },
  },
}
</script>
